@use "sass:map";
@import '../../../css/color.scss';
.labelFormControl{
  label{ background: $backgroundLightest;
    padding: 2px 6px;}
}
.dark{
  .labelFormControl{
    label{
      background: map.get($darkTheme, 'backgroundLightest');
    }
  }
}
