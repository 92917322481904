@import './color.scss';
@import './card.scss';
@import './loader.scss';
@import './button.scss';
@import './dropdown.scss';
@import './form.scss';
@import './table.scss';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 0.9rem;
  line-height:1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Roboto', '-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue',
  'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
}

*:focus {
  outline: 0;
}

.mr-xs {
  margin-right: 0.6rem;
}

.ml-xs {
  margin-left: 0.6rem;
}

.mb-xs {
  margin-bottom: 0.6rem;
}

.float-right {
  float: right;
}
