@import './color.scss';

.myListItem {
  display: flex;
  flex-flow: row wrap;
  gap: 30px 2%;
  width: 100%;
  height: 100%;

  li {
    box-sizing: border-box;
    flex-basis: 32%;
    min-width: 32%;
    min-height: 72px;
    -webkit-box-shadow: 0 2px 5px 0 $shadow;
    -ms-box-shadow: 0 2px 5px 0 $shadow;
    -o-box-shadow: 0 2px 5px 0 $shadow;
    box-shadow: 0 2px 5px 0 $shadow;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }

  @media (min-width: 600px) and (max-width: 959px) {
    li {
      flex-basis: 49%;
      min-width: 49%;
    }
  }
  @media(max-width: 600px) {
    li {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}

.dark {
  .myListItem {
    li {
      background-color: rgba($backgroundLight, 0.12);

      .avatarColor {
        svg {
          color: $foregroundLightest;
        }
      }
    }
  }
}