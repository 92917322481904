@import '../../../css/color.scss';


.bg-gray-light {
  background: $backgroundLighter;
  color: $foregroundDarkest;
}

.app {
  .MyCardroot {
    display: flex;
    justify-content: space-between;
    min-height: 190px;
    cursor: pointer;
    position: relative;
    @media(min-width: 960px) and (max-width: 1060px) {
      min-height: 212px;
    }
    @media(max-width: 959px) {
      min-height: auto;
    }
  }

  .MyCarddetails {
    display: flex;
    flex-direction: column;
  }

  .MyCardcontent {
    flex: 1 0 auto;
    cursor: pointer;
    padding-bottom: 16px !important;

    .price {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .title {
      font-weight: bold;
    }
    .small-text {
      font-size: 0.8rem;
      line-height: 1rem;
      margin-bottom: 20px;
    }
  }

  .MyCardcover {
    width: 150px;
  }


  .Link {
    font-size: 0.8rem;
    position: absolute;
    left: 16px;
    bottom: 8px;

    span {
      color: $foregroundDarkSofter;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &.dark{
    .cardGray, .cardWhite, .cardGrayLight {
      .MyCarddetails{
        color: $foregroundDark;
        .MyCardcontent .small-text{
          color: $foregroundDark;
        }
      }
    }
    .Link {
      span{
        color: $foregroundLightest !important;
      }
    }
  }
}

.cardGreen {
  .MyCardroot {
    background-color: $backgroundPrimary;
    position: relative;
    @media(min-width: 736px) and (max-width: 959px) {
      min-height: 190px;
    }
    @media(max-width: 736px) {
      display: block;
      min-height: 296px;
    }
  }

  .MyCardcontent {
    color: $foregroundLightest !important;
  }

  .MyCardcover {
    background-size: 100px;
    @media(max-width: 736px) {
      background-size: 100px;
      height: 100px;
      width: 100px;
      margin: 0 auto;
    }
  }

  .small-text {
    @media(max-width: 736px) {
      margin-bottom: 0px!important;
    }
  }

  .Link {
    font-size: 0.8rem;
    position: absolute;
    left: 16px;
    bottom: 8px;
    @media(max-width: 736px) {
      position: static;
      padding: 16px;
    }

    span {
      color: $foregroundLightest;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.cardWhite {
  .MyCardroot {
    background-color: $backgroundLightest;
    position: relative;
    min-height: 70px;
  }

  .MyCardcover {
    width: 150px;
    background-size: 50px;
    background-position: 115px;
  }

  .title {
    margin-bottom: 10px;
  }

  .small-text {
    margin-bottom: 0px;
  }
}

.cardGray, .cardGrayLight {
  .MyCardroot {
    background-color: #e5e7f0;
    position: relative;
    margin-bottom: 20px;
    min-height: 85px;
    background-image: url("../../../images/foodcard/sandwich.jpg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    @media(max-width: 736px) {
      min-height: 138px;
    }
    @media(max-width: 414px) {
      min-height: 145px;
    }

  }

  .MyCarddetails {
    justify-content: space-between;
  }

  .title {
    margin-right: 15px
  }

  .MyCardcontent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 736px) {
      display: block;
    }
  }

  .small-text {
    margin-bottom: 0px!important;
  }
}

.cardGrayLight {
  .MyCardroot {
    background-color: #F4F4F4;
    position: relative;
    margin-bottom: 0px;
    background-image: url("../../../images/foodcard/sweet.jpg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }
}