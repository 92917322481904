.pending-contract-step {
  width: 90%;
  margin: 2rem auto;
  text-align: center;

  .pending-content-info {
    margin-bottom: 1rem;
  }
  .inputInline{display: inline}
  .w-50{width: 50%}
}

.pending-contract-view {
  width: 100%;
  margin: 2rem auto;
  text-align: center;
}
