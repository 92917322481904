@import '../../css/color.scss';

.pointer {
  cursor: pointer;
}

.bg {
  background: url("../../images/common/bgapp-interne.png") no-repeat top center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &.dark {
    background-color: $foregroundDark;
    background-image: url("../../images/common/bgapp-interne_dark.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

}

.fixFloat {
  clear: both
}

.app {
  min-height: -webkit-calc(100vh - 70px);
  min-height: -moz-calc(100vh - 70px);
  min-height: calc(100vh - 70px);
  z-index: 2;
  position: relative;

  &.payment_result {
    .navbar {
      display: none;
    }
  }

  &.dark {
    .textColorPrimary {
      color: $info;
    }
  }

  .navbar {
    margin: 0 auto 2rem;
    width: 100%;
    @media (max-width: 598px) {
      margin: 0 auto 2rem;
    }
  }

  .content {
    margin: 0 auto;
    width: 95%;
    padding-bottom: 50px;

    .root-container {
      display: flex;
      flex-direction: row;
    }
  }
}

.justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.colorTextSecondary {
  color: $foregroundDarkSofter;
}

.textColorPrimary {
  color: $foregroundPrimary;
}

.textColorDark {
  color: $foregroundDark;
}

.textColorWarning {
  color: $warning;
}

.textColorSuccess {
  color: $success;
}

.textColorSecondaryDark {
  color: $foregroundSecondaryDarker;
}

.textColorInfo {
  color: $info;
}

.textColorMuted {
  color: $foregroundDarkSofter;
}

.textColorInfo {
  color: $info;
}

.textLineThrough {
  text-decoration: line-through;
}

.linkIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pending-contract {
  width: 80%;
  margin: 0 auto;
  text-align: center;

  .button {
    background-color: $backgroundSecondary;
    color: $foregroundDarkest;
    font-size: 1.4rem;
  }
}

.right {
  float: right;
}

.pt-30 {
  padding-top: 30px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.snackbar {
  width: 100%;
  height: 5vh;
  position: sticky;
  bottom: 5rem;
}

.bgDark {
  background-color: $backgroundSecondary;
}

.clear {
  clear: both;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.IconConciergerie {
  width: 30px;
  height: 30px;
}

.flex-align-center {
  display: flex;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.text-error {
  color: $error;
}

.smallIcon {
  margin-bottom: -2px;
  margin-right: 5px;
  font-size: 0.9rem !important;
}

.textIcon {
  margin-bottom: -5px;
  margin-left: 5px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-normal {
  font-size: 1rem;
}

.pb-30 {
  padding-bottom: 30px;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ModalResponse, .ModalDelete {
  .MuiDialog-paper {
    padding: 16px;
  }

  button {
    margin: 0 auto;

    &.block {
      display: block;
    }
  }
}

.mobile-only{
  display: none !important;
}
@media(max-width: 414px) {
  .mobile-only {
    display: inline-block !important;
  }
}
