.myCard {
  min-height: 185px;

  .card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba($borderPrimary, 0.5);
    padding-bottom: 10px;

    &.list-header {
      justify-content: flex-start;
      align-items: center;

      hr {
        margin: 0 10px 0 6px;
      }
    }

    &.no-flex {
      display: block;
    }
  }

  .card-content {
    padding-top: 20px
  }

  .card-content-titre, .card-content-item-list, .table-content-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .table-content-cell {
    display: flex;
    flex-direction: row;
    float: right;
  }

  .table-content-item {
    font-size: 10px;
  }

  .myCardLoader {
    padding-top: 3rem;
    display: flex;
    justify-content: center
  }

  .card-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .subtitle {
    font-size: 12px;
  }

  .profile-last-connection {
    text-align: right;
  }

  .avatarColor {
    background-color: $backgroundSecondary;

    &.small {
      width: 25px;
      height: 25px;
    }

    &.mb-5 {
      margin-right: 5px;
    }

    &.primary {
      background-color: $backgroundPrimary;
    }

    &.info {
      background-color: $info;
    }

    &.gray {
      background-color: $backgroundLight;
    }

    &.primary-light {
      background-color: rgba($backgroundPrimary, 0.6);
    }

    &.outline {
      background-color: transparent;
      border: 1px solid $foregroundDarkSofter;

      svg {
        color: $foregroundDarkSofter;
      }

      &.pending {
        background-color: transparent;
        border: 1px solid $info;

        svg {
          color: $info;
        }
      }
    }
  }

  &.myProfile,
  &.myPassword {
    .card-content {
      margin-top: 10px
    }

    .card-actions {
      margin-top: 20px
    }
  }

  a {
    &.colorTextSecondary {
      text-decoration: none;
    }
  }

  .pending {
    .avatarColor {
      &.primary {
        background-color: $backgroundLightest !important;

        svg {
          color: $info;
        }
      }
    }
  }
}

.myCardLoader {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MyCardHomeroot {
  position: relative;
  min-height: 352px;
  @media(max-width: 599px) {
    min-height: auto;
  }
  @media(min-width: 600px) and (max-width: 959px) {
    min-height: 337px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba($borderPrimary, 0.5);
    padding-bottom: 10px;
  }

  .wrapButton {
    display: flex;
    justify-content: center;
  }

  .MyCardHomeroot-inner-text {
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;
    color: $foregroundLightest;
    font-weight: bold;
  }

  .centerCard {
    background-image: url("../images/common/background-icon-card.png");
    background-position: 102% 65%;
    background-repeat: no-repeat;
    background-size: 50px auto;
  }
}

.displayNone {
  display: none;
}

.accounting_content, .quotations_content {
  display: flex;
  flex-flow: row wrap;
  gap: 30px 2%;

  .myCardList {
    box-sizing: border-box;
    flex-basis: 32%;
    min-width: 32%;
    min-height: 72px;
    -webkit-box-shadow: 0 2px 5px 0 $shadow;
    -ms-box-shadow: 0 2px 5px 0 $shadow;
    -o-box-shadow: 0 2px 5px 0 $shadow;
    box-shadow: 0 2px 5px 0 $shadow;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;

    .myCardList-header {
      &.default {
        background-color: $backgroundLight;

        .MuiSvgIcon-root {
          color: $foregroundDarkSofter
        }
      }

      &.pending {
        background-color: $info;
        color: $foregroundLightest !important;

        p {
          color: $foregroundLightest !important;
        }

        .MuiSvgIcon-colorDisabled {
          color: $disabledIcon;
        }
      }

      &.late {
        background-color: $warning;
      }

      &.paid {
        background-color: $info;
      }

      &.validated {
        background-color: $info;
      }

      &.created {
        background-color: $foregroundSecondary;
      }
    }

    .myCardList-content {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .item {
        flex: 1 1 auto;
      }
    }

    @media (min-width: 600px) and (max-width: 959px) {
      flex-basis: 49%;
      min-width: 49%;
    }
    @media(max-width: 600px) {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}

.dark {
  background-color: rgba($backgroundLight, 0.12);

  li {
    p {
      span {
        color: $foregroundLightest;
      }
    }

    span {
      span {
        color: $foregroundLightest;
      }
    }
  }

  .avatarColor {
    &.outline {
      background-color: transparent;
      border: 1px solid $borderLighter;

      svg {
        color: $foregroundLighter;
      }
    }
  }

  .accounting_content, .quotations_content {
    .myCardList {
      background-color: rgba($backgroundLight, 0.12);

      .myCardList-header {
        &.default {
          color: $foregroundDark;

          .MuiSvgIcon-root {
            color: $foregroundDark
          }

          p {
            color: $foregroundDark;
          }
        }

        li {
          span {
            color: $foregroundDark;
          }
        }
      }

      .myCardList-content {
        span {
          color: $foregroundLight;
        }
      }

      @media (min-width: 600px) and (max-width: 959px) {
        flex-basis: 49%;
        min-width: 49%;
      }
      @media(max-width: 600px) {
        flex-basis: 100%;
        min-width: 100%;
      }
    }
  }

  .MyCardHomeroot, .myCard {
    .card-header {
      border-bottom: 1px solid $borderCard;
    }
  }

  .myCard {
    .default {
      .avatarColor {
        &.primary {
          background-color: $foregroundDarkSofter;

          svg {
            color: $foregroundLightest;
          }
        }
      }
    }

    &.payment_details, &.contract_details {
      li {
        p {
          span {
            color: $foregroundLightest;
          }
        }

        span {
          span {
            color: rgba($foregroundLightest, 0.7);
          }
        }
      }
    }
  }
}

.iconLink {
  margin-top: 3px;
  font-size: 0.95rem !important;
  position: relative;
  top: 3px;
  margin-left: 10px;
}