@import './color.scss';

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $backgroundLightest;
  border-color: $borderPrimary;
  width: 60%;
  margin: 0 auto;
  box-shadow: 0px 8px 16px 0px $shadow;
  z-index: 1;
  text-align: center;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content p {
  margin: 0;
  padding: 0;
}

.dropdown:hover .dropdown-content:hover {
  background-color: lightgray;
}
