@import '../../../css/color.scss';

.app {

  &.quotations {
    .navbar {
      display: none;
    }
  }

  .ContactContainer {
    float: right;
    margin-bottom: 20px;

    .contact-menu-btn {
      white-space: nowrap;
    }
  }

  .quotationscard-container {
    .cardTitleQuotations {
      text-align: center;
      color: $foregroundLightest;
      background-color: $backgroundPrimary;
      padding: 8px 16px;
    }

    .accordion-quotations-details-header {
      border-bottom: 1px solid rgba($borderPrimary, 0.5);
    }

    .accordion-quotations-details-body {
      display: block;
      padding-top: 16px;
    }

    .accordion-solution-block {
      .accordion-solution-header {
        flex-direction: row-reverse;

        .MuiAccordionSummary-content {
          margin-left: 16px;

          &.Mui-expanded {
            margin-top: 12px;
            margin-bottom: 12px;
          }
        }

        .MuiButtonBase-root {
          padding-left: 0;
          padding-right: 0;
        }

        .title-accordion-solution-block {
          span {
            font-weight: bold;
            color: $foregroundLightest;
          }
        }
      }

      margin-bottom: 20px;

      .accordion-solution-header {
        &.primary {
          font-size: 0.875rem;
          color: $foregroundLightest;
          background-color: $backgroundPrimary;

          span {
            color: rgba($foregroundLightest, 0.7);
          }
        }

        &.grey {
          font-size: 0.875rem;
          color: $foregroundPrimary;
          background-color: $backgroundLight;

          span {
            color: rgba($foregroundPrimary, 0.7);
          }
        }

        &.yellowLight {
          font-size: 0.875rem;
          background-color: rgba($backgroundSecondary, 0.1);
          border-bottom: 1px solid $borderSecondary;

          span {
            color: $info;

            svg {
              color: $foregroundDark;
            }
          }
        }
      }

      .accordion-solution-body-level-0 {
        padding: 0;

        &.pt-20 {
          padding-top: 20px;
        }
      }

      .accordion-solution-body {
        padding: 8px;
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: auto !important;
      }
    }

    .contact-card-background {
      background-image: url("../../../images/quotations/bg-card.png");
      background-repeat: no-repeat;
      background-position: 100% 70%;
      background-size: auto 80px;
    }

    .myCard {
      min-height: 191px;
    }

    .titleQuotationsNumber {
      color: $foregroundLightest;
      background-color: $backgroundPrimary;
      font-weight: bold;
      font-size: 1rem;
    }

    table {
      &.myTableQuotationsCenter {
        thead {
          th {
            color: $foregroundPrimary;
            font-weight: bold;
            padding: 0;
            font-size: 16px;
            border: none;

            &.pr10 {
              padding-right: 10px;
            }
          }
        }

        tbody {
          td {
            padding: 0;
            border: none;

            &.pr10 {
              padding-right: 10px;
            }

            &.pl25 {
              padding-left: 25px;
            }
          }
        }

        .IconAvantages {
          width: 70px;
          height: 70px;
        }

        .Arrow {
          width: 70px;
          height: 70px;
        }
      }

      &.myTableQuotationsAddServices,
      &.myTableQuotationsOption {
        margin-bottom: 16px;

        thead {
          th {
            padding: 3px 5px;
            line-height: 1rem;
            color: $foregroundLightest;
            background-color: $backgroundPrimary;
            border-bottom: none;

            span {
              position: relative;
              top: -6px;
            }

            svg {
              margin-right: 5px;
            }
          }
        }

        tbody {
          tr {
            &:last-child td {
              border-bottom: none;
            }
          }

          td {
            &.textColorPrimary {
              color: $foregroundPrimary;
              font-weight: bold;
            }

            &.border-bottom-yellow {
              border-bottom: 1px solid $borderSecondary;
            }

            &.border-bottom-bgGrey {
              border-bottom: 1px solid $borderLighter !important;
            }

            &.bordernone {
              border-bottom: none;
            }

            &.bgGrey {
              background-color: $backgroundLighter !important;
            }

            .textColorPrimary {
              color: $foregroundPrimary;
            }
          }
        }
      }
    }

    .myGridQuotations {
      padding-left: 0;
      padding-right: 0;

      .bgGrey {
         background-color: $backgroundLighter !important;
      }
    }

    .imgGoal {
      float: right;
      margin-left: 40px;
      width: 250px;
      height: 250px;
    }

    .gridQuotationsAddServices {
      padding-left: 20px;
      padding-right: 20px;

      .border-bottom-bgGrey {
        border-bottom: 1px solid $borderLighter !important;
      }

      .bgGrey {
         background-color: $backgroundLighter !important;
        min-height: 102px;
      }

      .textColorPrimary {
        color: $foregroundPrimary;
      }

      .IconAdditional {
        width: 70px;
        height: 70px;
        margin: 0 auto;
        display: block;
      }

      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .gridQuotationsGoal {
      padding-left: 20px;
      padding-right: 20px;

      .border-bottom-bgGrey {
        border-bottom: 1px solid $borderLighter !important;
      }

      .bgGrey {
         background-color: $backgroundLighter !important;
        min-height: 102px;
      }

      .textColorPrimary {
        color: $foregroundPrimary;
      }

      .IconGaol {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        display: block;
      }

      p {
        text-align: justify;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 598px) {
          text-align: center;
        }
      }
    }

    .bkGreen {
      background-color: $backgroundPrimary;
      color: $foregroundLightest;

      .IconGaol {
        width: 50px;
        height: 50px;
        margin: 0 auto;
      }
    }

    .DividerYellow {
      background-color: $backgroundSecondary;
    }

    .quotations-tab {
      min-width: 100%;
      margin-bottom: 20px;
      box-shadow: inset 0 -1px 0 0 #E6ECF0;

      button {
        span {
          font-weight: normal;
          text-transform: none;
          white-space: nowrap;
        }

        &.Mui-selected {
          span {
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }
    }

    .ImgCenter {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }

    .IconInclusives {
      width: 45px;
      height: 45px;
      display: block;
      margin: 0 auto;
    }

    .iframeQuotations {
       background-color: $backgroundLighter;
    }

    .visitLink {
      float: right;
      @media (max-width: 598px) {
        float: none;
      }
    }

    @media (max-width: 598px) {
      .display-proposed-solutions {
        display: none;
      }
    }

    .TitleContentTab {
      color: $foregroundPrimary;
      font-weight: bold;
    }

    .SubTitleContentTab {
      background-color: $backgroundPrimary;
      color: $foregroundLightest;
      font-weight: bold;
      padding: 3px 5px;
      text-transform: uppercase;
    }

    .TitleExperience {
      font-size: 16px;
    }
    .List-nomade{
        text-align: center!important;
      div{
        border-width: 1px;
        border-style: solid;
        border-color: $borderLight;
        padding: 2px 10px;
        &.Header-list-nomade{
          background-color: $backgroundPrimary;
          color: $foregroundLightest;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center!important;
          border-color: $foregroundPrimary;
        }
        &.Price-list-nomade{
          text-align: center!important;
          border-bottom: 0;
          span{
            font-weight: bold;
            font-size: 1.2rem;
          }
          .Price-list-nomade-where{
            text-transform: uppercase;
            font-weight: bold;}
          &.green{
            background-color: $backgroundPriceListNomad;
            color: $foregroundPrimary;
            border-bottom: none;
            p{margin-bottom: 0!important;}
            .Price-list-nomade-where{
              color: $foregroundLightest;
              background-color: rgba($backgroundPrimary, 0.6);
            }
          }
          &.dark{
            color: $foregroundDark;
            border-top-width: 0;
            .Price-list-nomade-where{
              color: $foregroundLightest;
              background-color:$foregroundDark ;
            }
          }
        }
        &.Text-list-nomade{
          min-height: 87px;
          @media(max-width: 960px) {
            min-height: auto;
          }
          @media(min-width: 961px) and (max-width: 1142px) {
            min-height: 106px;
          }
        }
      }
    }
  }

  .floatingButtonContact {
    position: fixed;
    bottom: 8px;
    right: 8px;

    span {
      color: $foregroundLightest;
    }
  }

  .line-contact {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}

.ModalContact {
  .myCard {
    padding: 16px;
  }

  .close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .line-contact {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}