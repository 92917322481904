@import '../../../css/color.scss';

.app.login {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../images/login/bgLogin.png");

  &.dark {
    background-image: url("../../../images/login/bgLogin_dark.png");
  }

  .content {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }

  .bkContainerLogin,
  .bkContainerLoginDark {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    max-width: 460px;
    backdrop-filter: blur(10px);
    background-color: rgba($backgroundPaper, 0.82);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      margin: 20px;
      padding: 0;
    }
  }

  .bkContainerLogin {
    background-color: rgba($backgroundLightest, 0.82);
  }

  .bkContainerLoginDark {
    background-color: rgba($backgroundPaper, 0.82);
  }

  .logo {
    width: $logoLoginWidth;
    display: block;
    margin: 0 auto;
    height: auto;
    margin-top: 20px;
  }

  .btnLogin {
    height: 36px;
  }

  .navbar {
    display: none;
  }

  .login-form {
    width: auto !important;

    button {
      color: $backgroundLightest;

      &:hover,
      &:focus {
        background-color: $backgroundLightest;
        color: $foregroundPrimary;
        border: 1px solid $borderPrimary;
      }
    }
  }
}
