@import '../../../css/color';

.accountingDocument-list-filters,
.accountingDocument-list-root {
  flex: 4;
  margin: 0;
}

td a {
  text-decoration: none;
  margin-left: 5px;

  span {
    padding-left: 0.3rem;
  }
}

.pagination {
  margin-bottom: 1rem;
}
.sepaInfo{
  background-color:$info;
  color: $foregroundLightest;
  padding: 5px;
  font-weight: bold;
  margin-top: 20px;
  .btn-sepa {
  padding: 0.2rem 0.4rem;
 }
}