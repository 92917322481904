@import '../../../css/color';

.documents_details {
  li {
    &.primary {
      background-color: $backgroundPrimary;
      color: $foregroundLightest;

      p {
        color: $foregroundLightest;
      }

      .MuiSvgIcon-root {
        color: $foregroundLightest
      }
    }
  }
}