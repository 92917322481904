@import '../../../css/color';

.contract_details {
  li {
    &.default {
      background-color: $backgroundLight;

      .MuiSvgIcon-root {
        color: $foregroundDarkSofter
      }
    }

    &.info {
      background-color: $info;
      color: $foregroundLightest;

      p {
        color: $foregroundLightest;
      }

      .MuiSvgIcon-root {
        color: $foregroundLightest
      }
    }

    &.primary {
      background-color: $backgroundPrimary;
      color: $foregroundLightest;

      p {
        color: $foregroundLightest;
      }

      .MuiSvgIcon-root {
        color: $foregroundLightest
      }
    }

    &.secondary {
      background-color: $backgroundSecondary;
      color: $foregroundDarkSofter;

      p {
        color: $foregroundDarkSofter;
      }

      .MuiSvgIcon-root {
        color: $foregroundLightest
      }
    }

    &.pending {
      background-color: $info;
      color: $foregroundLightest !important;

      p {
        color: $foregroundLightest !important;
      }

      .MuiSvgIcon-colorDisabled {
        color: $disabledIcon;
      }
    }
  }
}

.dark {
  .contract_details {
    li {
      &.default {
        span {
          color: $foregroundDark !important;
        }

        p {
          color: $foregroundDarkSofter !important;
        }

        .MuiSvgIcon-root {
          color: $foregroundDarkSofter
        }
      }

      &.pending {
        background-color: $info;
        color: $foregroundLightest !important;

        p {
          color: $foregroundLightest !important;
        }

        .MuiSvgIcon-colorDisabled {
          color: $disabledIcon;
        }
      }
    }
  }
}