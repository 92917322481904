@import '../../../css/color.scss';

.app {
  .paperHelpdesk {
    padding: 8px;
  }

  .bkColHelpdesk {
    hr {
      background-color: $backgroundLightest;
      margin-bottom: 32px;
    }

    color: $foregroundLightest;
    background: url("../../../images/helpdesk/bkColHelpdesk-vertical.jpg") no-repeat center left;
    background-size: cover;
    @media(max-width: 959px) {
      background: url("../../../images/helpdesk/bkColHelpdesk-horizontal.jpg") no-repeat bottom right;
      background-size: cover;
    }
    @media(max-width: 600px) {
      background: none;
      height: auto;
      .button {
        position: absolute;
        right: 24px;
        top: 8px;

        &.bg-white {
          background: $backgroundPrimary;
          border: thin solid $borderPrimary;
          color: $foregroundLightest;

          &.center {
            position: static;
            display: block;
            width: 160px;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
      h2, p {
        color: $foregroundPrimary;
      }
      p {
        margin-top: 16px;
        margin-bottom:0;
      }
      hr {
        background-color: $backgroundPrimary;
        margin-bottom: 0;
      }
      .small-y {
        padding: 16px 16px 0px 16px;
      }
    }
    @media(min-width: 960px) {
      min-height: 497px;
    }


    position: relative;

    .backCategory {
      cursor: pointer;
      vertical-align: middle;
      text-align: center;
      border: 1px solid $borderLightest;
      padding: 4px 5px 1px 5px;
      position: absolute;
      top: 26px;
      right: 22px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &.show {
        display: table-cell;
      }

      &.hide {
        display: none;
      }

      @media(max-width: 600px) {
        color: $foregroundPrimary;
        right: 160px;
        top: 10px;
      }
    }
  }

  .rightColHelpdesk {
    position: relative;
    min-height: 430px;
    @media(max-width: 959px) {
      min-height: 465px;
    }
    @media(max-width: 600px) {
      .pt-0 {
        padding-top: 0px !important;
      }
      .mt-0 {
        margin-top: 0px !important;
      }
    }
  }

  .boxFormHelpdesk, .boxMessageHelpdesk {
    position: absolute;
    top: 0;
    left: 0;

    .w-100 {
      @media(max-width: 600px) {
        width: 100%
      }
    }
  }

  .boxFormHelpdesk, .boxMessageHelpdesk {
    &.show {
      opacity: 1;
      display: block;
      visibility: visible;
      top: 0;
      left: 0;
      -webkit-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      transition: opacity 1s ease;
      padding-top: 16px;
      @media(max-width: 959px) {
        position: relative;
      }
    }

    &.hide {
      opacity: 0;
      display: block;
      visibility: hidden;
    }
  }

  .boxCategoryHelpdesk {
    &.show {
      display: block;
      opacity: 1;
      -webkit-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      transition: opacity 1s ease;
      visibility: visible;
    }

    &.hide {
      display: block;
      opacity: 0;
      visibility: hidden;
      @media(max-width: 959px) {
        position: absolute;
      }
    }

    @media(max-width: 600px) {
      padding-top: 0px;
    }
  }

  ul {
    &.formIconHelpdesk {
      li {
        .avatarSizeHelpdesk {
          font-size: 2rem;
        }

        .avatarColorHelpdesk {
          margin-bottom: 10px;
          background-color: $backgroundLighter;
          border-width: 1px;
          border-style: solid;
          border-color: $foregroundDark;
          background-color: $foregroundDark;
          color: $foregroundLightest;
        }

        &.primary {
          .avatarColorHelpdesk {
            border-color: $borderPrimary;
            background-color: $backgroundPrimary;
            color: $foregroundLightest;
          }
        }

        &.secondary {
          .avatarColorHelpdesk {
            border-color: $borderSecondary;
            background-color: $backgroundSecondary;
            color: $foregroundLightest;
          }
        }

        &.info {
          .avatarColorHelpdesk {
            border-color: $info;
            background-color: $info;
            color: $foregroundLightest;
          }
        }
      }
    }

    &.gridIconHepldesk {
      padding: 0;
      list-style: none;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      row-gap: 15px;
      column-gap: 15px;

      li {
        cursor: pointer;
        border: 1px solid $borderLight;
        border-radius: 4px;
        text-align: center;
        padding: 16px;
        flex-direction: column;
        box-sizing: border-box;
        width: 31%;
        @media(max-width: 959px) {
          text-align: left;
          flex-direction: row;
          padding: 8px 8px 0;
        }
        @media(max-width: 600px) {
          width: 98%;
        }

        span {
          font-size: 0.75rem;
          @media(min-width: 1200px) {
            font-size: 0.80rem;
          }
        }

        .avatarSizeHelpdesk {

          @media(min-width: 960px) {
            font-size: 2rem;
          }
        }

        .avatarColorHelpdesk {
          margin-bottom: 10px;
          background-color: $backgroundLighter;
          border-width: 1px;
          border-style: solid;
          border-color: $foregroundDark;
          color: $foregroundDark;
          @media(min-width: 960px) {
            width: 56px;
            height: 56px;
          }

          &:hover {
            background-color: $backgroundLightest;
          }
        }

        &.primary {
          .avatarColorHelpdesk {
            border-color: $borderPrimary;
            color: $foregroundPrimary;

            &:hover {
              background-color: $backgroundLightest;
            }
          }
        }

        &.secondary {
          .avatarColorHelpdesk {
            border-color: $borderSecondary;
            color: $foregroundSecondary;

            &:hover {
              background-color: $backgroundLightest;
            }
          }
        }

        &.info {
          .avatarColorHelpdesk {
            border-color: $info;
            color: $info;

            &:hover {
              background-color: $backgroundLightest;
            }
          }
        }

        &:hover {
          background-color: $foregroundDark;
          border-color: $foregroundDark;
          color: $foregroundLightest;
          -webkit-transition: background-color 100ms linear;
          -ms-transition: background-color 100ms linear;
          -o-transition: background-color 100ms linear;
          transition: background-color 100ms linear;
          -webkit-box-shadow: 0 2px 5px 0 $shadow;
          -ms-box-shadow: 0 2px 5px 0 $shadow;
          -o-box-shadow: 0 2px 5px 0 $shadow;
          box-shadow: 0 2px 5px 0 $shadow;

          &.primary {
            background-color: $backgroundPrimary;
            border-color: $borderPrimary;
            color: $foregroundLightest;

            .avatarColorHelpdesk {
              &:hover {
                background-color: $backgroundLightest;
              }
            }
          }

          &.secondary {
            background-color: $backgroundSecondary;
            border-color: $borderSecondary;
            color: $foregroundDark;

            .avatarColorHelpdesk {
              &:hover {
                background-color: $backgroundLightest;
              }
            }
          }

          &.info {
            background-color: $info;
            border-color: $info;
            color: $foregroundLightest;

            .avatarColorHelpdesk {
              &:hover {
                background-color: $backgroundLightest;
              }
            }
          }
        }
      }
    }
  }

  .card-List-block {
    height: 497px;
    padding-top: 0px;
    overflow-y: scroll;
  }

  .card-list-scroll {
    overflow: scroll;
  }

  .card-list {
    border: thin solid rgba($borderLightest, 0.12);
    border-radius: 4px;
    font-size: 0.8rem;

    .card-list-header {
      background-color: $backgroundLight;
      padding: 2px;
      cursor: pointer;

      svg {
        &.arrow {
          float: right;
          font-size: 1rem;
          margin-right: 8px;
          margin-top: 12px;
          -webkit-transition: -webkit-transform .2s ease-in-out;
          -ms-transition: -ms-transform .2s ease-in-out;
          transition: transform .2s ease-in-out;
        }
      }

      &.open {
        svg {
          &.arrow {
            transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
          }
        }

        .avatarColorHelpdesk {
          margin-top: 0px;
          width: 28px;
          height: 28px;
          background-color: $foregroundDark;
          border-width: 1px;
          border-style: solid;
          border-color: $foregroundDark;

          span {
            color: $foregroundLighter;
          }
        }

        &.treated {
          background-color: $backgroundPrimary;
          color: $foregroundLightest;

          .avatarColorHelpdesk {
            border-color: $borderPrimary;
            background-color: $backgroundLightest;

            span {
              color: $foregroundPrimary;
            }
          }
        }

        &.inprogress {
          background-color: $info;
          color: $foregroundLightest;

          .avatarColorHelpdesk {
            border-color: $info;
            background-color: $backgroundLightest;

            span {
              color: $info;
            }
          }
        }

        &.created {
          background-color: $backgroundSecondary;
          color: $foregroundDark;
          .avatarColorHelpdesk {
            border-color: $borderSecondary;
            background-color: $backgroundLightest;

            span {
              color: $foregroundSecondary;
            }
          }
        }

        &.cancelled {
          background-color: $foregroundDarkSofter;
          color: $foregroundLightest;

          .avatarColorHelpdesk {
            border-color: $foregroundDarkSofter;
            background-color: $backgroundLightest;

            span {
              color: $foregroundDarkSofter;
            }
          }
        }
      }

      .icon {
        svg {
          &.statusIcon {
            color: $foregroundDarkSofter;
          }
        }
      }

      &.open {
        border-bottom: thin solid rgba($borderLightest, 0.12);
      }

      p {
        font-size: 0.8rem;
      }

      span {
        strong {
          color: $foregroundDark;
        }
      }

      .avatarSizeHelpdesk {
        font-size: 1rem;
      }

      .avatarColorHelpdesk {
        margin-top: 0px;
        width: 28px;
        height: 28px;
        background-color: $backgroundLighter;
        border-width: 1px;
        border-style: solid;
        border-color: $foregroundDark;

        span {
          color: $foregroundDark;
        }
      }

      &.treated {
        .avatarColorHelpdesk {
          border-color: $borderPrimary;
          background-color: $backgroundPrimary;

          span {
            color: $foregroundLightest;
          }
        }
      }

      &.inprogress {
        .avatarColorHelpdesk {
          border-color: $info;
          background-color: $info;

          span {
            color: $foregroundLightest;
          }
        }
      }

      &.created {
        .avatarColorHelpdesk {
          border-color: $borderSecondary;
          background-color: $backgroundSecondary;

          span {
            color: $foregroundLightest;
          }
        }
      }

      &.cancelled {
        .avatarColorHelpdesk {
          border-color: $foregroundDarkSofter;
          background-color: $foregroundDarkSofter;

          span {
            color: $foregroundLightest;
          }
        }
      }
    }

    .block-list {
      float: left;
      box-sizing: border-box;
      padding: 4px 8px;
      @media(max-width: 600px) {
        width: 33%;
      }

      span {
        line-height: 1rem;
        font-size: 0.65rem;
        color: $foregroundDarkSofter;

      }

      p {
        margin-bottom: 0;
        font-size: 0.65rem;
        @media(min-width: 960px) {
          font-size: 0.8rem;
        }
      }

      &.date, &.number {
        width: 38%;
        padding-top: 9px;
        @media(max-width: 959px) {
          padding-top: 12px;
        }
        @media(max-width: 600px) {
          width: 80%;
          padding-top: 4px;
        }
      }

      &.category {
        width: 50%;
        padding-top: 8px;

        p {
          display: flex;
          line-height: 20px;
        }

        @media(max-width: 600px) {
          padding-left: 43px;
          padding-top: 0;
          width: 100%;
          span {
            display: none
          }
          p {
            margin-top: -19px;
          }
        }
      }

      &.icon {
        width: 35px;
        padding-top: 4px;
      }

      &.sub-category {
        width: 22%;
        @media(max-width: 600px) {
          width: 50%;
        }
      }

      &.status {
        width: 22%;
        @media(max-width: 600px) {
          width: 100%;
        }
      }
    }

    .block-message {
      padding: 16px 8px;
      border-top: thin solid $borderLight;

      .block-message-header {
        line-height: 1rem;
        font-size: 0.65rem;
        color: $foregroundDarkSofter;
        text-transform: uppercase;

        svg {
          float: right;
          font-size: 1rem;
          margin-bottom: -3px;
          -webkit-transition: -webkit-transform .2s ease-in-out;
          -ms-transition: -ms-transform .2s ease-in-out;
          transition: transform .2s ease-in-out;
        }

        &.open {
          svg {
            transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
          }
        }
      }

      .block-message-content {
        padding: 0px 0 0;

        &.show {
        }

        &.hide {
        }
      }
    }

    .card-list-body {
      ul {
        li {
          padding-left: 8px !important;
          padding-right: 8px !important;

          span {
            font-size: 0.8rem;

            &.label {
              text-transform: uppercase;
              color: $foregroundDarkSofter;
            }

            &.label_card {
              background-color: $foregroundDarkSofter;
              border-radius: 2px;
              padding: 0 3px;
              color: $foregroundLightest;
              font-weight: bold;
              font-size: 0.65rem;
            }
          }
        }

        &.created {
          li {
            span {
              &.label_card {
                background-color: $backgroundSecondary;
                color: $foregroundDark;
              }
            }
          }
        }
        &.treated {
          li {
            span {
              &.label_card {
                background-color: $backgroundPrimary;
              }
            }
          }
        }
        &.inprogress {
          li {
            span {
              &.label_card {
                background-color: $info;
              }
            }
          }
        }
      }
    }
  }

  .fix-float {
    clear: both
  }
  &.dark{
    .bkColHelpdesk,.bkColImgBk {
      color: $foregroundLightest;
      @media(max-width: 600px) {
        h2, p {
          color: $foregroundLightest;
        }
        hr {
          background-color: $backgroundLightest;
        }
        .backCategory {
          color: $foregroundLightest;
        }
      }
    }
    ul {
      &.gridIconHepldesk {

        li {
          cursor: pointer;
          border: 1px solid rgba($borderLight,0.32);
        }
      }
    }
    .card-list {
      border: thin solid rgba($borderLightest, 0.12);
      border-radius: 4px;
      font-size: 0.8rem;

      .card-list-header {
        background-color: rgba($backgroundLight,0.12);
      }


      .card-list-body {
        background-color: rgba($backgroundLight,0.12);
        ul {
          li {

            span {

              &.label {
                color: $foregroundDarkSofter;
              }

              &.label_card {
                background-color: $foregroundDarkSofter;
                border-radius: 2px;
                padding: 0 3px;
                color: $foregroundLightest;
                font-weight: bold;
                font-size: 0.65rem;
              }
            }
          }

          &.created {
            li {
              span {
                &.label_card {
                  background-color: $backgroundSecondary;
                  color: $foregroundDark;
                }
              }
            }
          }
          &.treated {
            li {
              span {
                &.label_card {
                  background-color: $backgroundPrimary;
                }
              }
            }
          }
          &.inprogress {
            li {
              span {
                &.label_card {
                  background-color: $info;
                }
              }
            }
          }
        }
      }
      .block-message {
        padding: 16px 8px;
        border-top: thin solid rgba($borderLight,0.32);
      }
    }
  }
}