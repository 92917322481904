@import './color.scss';

.button {
  padding: 0.4rem 0.8rem;
  margin: 0;
  border-radius: 4px;
  background: $backgroundPrimary;
  color: $foregroundLightest;
  border: thin solid $borderPrimary;
  transition-duration: 0.4s;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: $foregroundPrimary;
    background-color: $backgroundLightest;
    border-color: $foregroundPrimary;
  }

  &:disabled {
    cursor: auto;
    color: $disabledForeground;
    background: $disabledBackground;
  }

  &.bg-secondary {
    background: $backgroundSecondary;
    border: thin solid $borderSecondary;
    color: $foregroundDarkest;

    &:hover {
      cursor: pointer;
      background-color: rgba($backgroundSecondary, 0.3);
    }

    &.large {
      font-size: 1.25rem;
    }
  }

  &.bg-info {
    background: $info;
    border: thin solid $info;
    color: $foregroundLightest;

    &:hover {
      cursor: pointer;
      background-color: rgba($info, 0.1);
      color: $info;
    }

    &.large {
      font-size: 1.25rem;
    }
  }
  &.bg-white {
    background: $backgroundLightest;
    border: thin solid $borderLightest;
    color: $foregroundPrimary;

    &:hover {
      cursor: pointer;
      background-color: $backgroundPrimary;
      color: $foregroundLightest;
    }

    &.large {
      font-size: 1.25rem;
    }
  }
}

.w-174 {
  display: inline-block;
  min-width:174px;
}

.floatRight {
  float:right;
}

.icon-button {
  margin-right: 5px;
  padding: 8px;
  color: $foregroundPrimary;
  border-radius: 50%;

  &:hover {
    background-color: rgba($backgroundPrimary, 0.04);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.small-loader {
  margin-left: 1rem;
  width: 20px !important;
  height: 20px !important;
}
.dark{
  .icon-button {
    color: $foregroundLightest;

    &:hover {
      background-color: rgba($foregroundLightest, 0.04);
    }
  }
}