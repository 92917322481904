@import '../../../css/color.scss';
.payment-tab {
  min-width: 100%;
  margin-bottom: 20px;
  box-shadow: inset 0 -1px 0 0 #E6ECF0;

  button {
    span {
      font-weight: normal;
      text-transform: none;
      white-space: nowrap;
    }

    &.Mui-selected {
      span {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}
#payment-form{
  width: 100%;
  padding: 0;
  box-shadow: none;
  border-radius:0;
  #card-element{
    padding: 18.5px 14px;
    box-shadow: none;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    min-height: 60px;
    box-sizing: border-box;
    &:hover{
      border-color:$borderPrimary;
    }
  }
}
.StripeButtons{
  border-radius: 4px!important;
  margin-top: 16px;
  margin-bottom: 16px;
}
#simple-tab-1{
  form {
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    input{
      box-shadow: none;
      border:1px solid rgba($foregroundDark, 0.23);
      border-radius: 4px;
      color:$foregroundDark;
      &:hover{
        border-color:$borderPrimary;
      }
      &:focus{
        border-color:$borderPrimary;
      }
    }
    label{
      color: rgba($foregroundDark, 0.54);
      font-size: 0.8rem;
    }
    .col{
      margin-bottom: 16px;
    }
    .mandate-acceptance{
      color: $foregroundDark;
      font-size: 0.8rem;
    }
  }
  .StripeElement{
    padding: 18.5px 14px;
    box-shadow: none;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    height: 20px;
    &:hover{
      border-color:$borderPrimary;
    }
  }
}

td.tdTotalPrice{
  color: $foregroundPrimary;
  font-size: 20px;
  border-bottom: none;
}
td.tdTotal{
  border-bottom: none;
}

a.noDecoration {
  text-decoration: none;
}

/* Buttons and links */
.StripeButtons {
  background: $backgroundPrimary;
  font-family: Arial, sans-serif;
  color: $foregroundLightest;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  width: 100%;
}

.border-y-1{
  border-top: 1px solid $borderLight;
  border-bottom: 1px solid $borderLight;
}
.listItem-header-payment{
display: flex;
  justify-content: space-between;
}
.payment_details{
  li {
    &.default {
      background-color: $backgroundLight;
    }

    &.pending {
      background-color: $info;
      color: $foregroundLightest !important;

      p {
        color: $foregroundLightest !important;
      }

      .MuiSvgIcon-colorDisabled {
        color: $disabledIcon;
      }
    }
  }
}
.dark{
  .payment_details {
    li {
      &.default {
        span{
          color: $foregroundDark!important;
        }
        p{
          color: $foregroundDarkSofter!important;
        }

        .MuiSvgIcon-root {
          color: $foregroundDarkSofter
        }
      }

      &.pending {
        background-color: $info;
        color: $foregroundLightest !important;

        p {
          color: $foregroundLightest !important;
        }

        .MuiSvgIcon-colorDisabled {
          color: $disabledIcon;
        }
      }
    }
  }
}