$backgroundLight: #E6E6E6;
$backgroundLighter: #F8F8F8;
$backgroundLightest: #FFFFFF;
$backgroundNavBar: #306B77;
$backgroundPaper: #3C4247;
$backgroundPaperLightest: #FFFFFF;
$backgroundPriceListNomad: #FCFCFC;
$backgroundPrimary: #306B77;
$backgroundSecondary: #FAD642;
$borderCard: rgba(255, 255, 255, 0.5);
$borderLight: #E6E6E6;
$borderLighter: #F8F8F8;
$borderLightest: #FFFFFF;
$borderPrimary: #306B77;
$borderSecondary: #FAD642;
$darkTheme: (
  background: #2A2E32,
  border: #3C4247,
  foreground: #ffffff,
  backgroundLightest: #2A2E32,
);
$disabledBackground: #cccccc;
$disabledForeground: #999999;
$disabledIcon: rgba(255, 255, 255, 0.8);
$error: #dd2c00;
$foregroundDark: #2A2E32;
$foregroundDarkest: #000000;
$foregroundDarkSofter: rgba(0, 0, 0, 0.54);
$foregroundLight: #E6E6E6;
$foregroundLighter: #F8F8F8;
$foregroundLightest: #FFFFFF;
$foregroundPrimary: #306B77;
$foregroundSecondary: #FAD642;
$foregroundSecondaryDarker: #b5a149;
$info: #47A1AD;
$shadow: rgba(0, 0, 0, 0.2);
$shadowDarker: rgba(0, 0, 0, 0.4);
$success: #4caf50;
$warning: #fa755a;
$logoLoginWidth: 350px;
$logoAppWidth: 150px;
$iconFooterWidth: 20px;
$foregroundOpacityLine: 1;
