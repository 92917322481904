@import '../../css/color.scss';

.footer {
  font-size: 0.7rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  background-color: $foregroundDark;
  color: $foregroundLight;
  padding: 16px;
  z-index: 2;
  position: relative;

  a {
    color: $foregroundLight;
    text-decoration: none;
    position: relative;

    &.underline::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 1px;
      background-color: $backgroundSecondary;
      -moz-transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      -webkit-transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      -o-transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
    @media (hover: hover) and (pointer: fine) {
      &.underline:hover::before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }

  .isotope-footer {
    width: $iconFooterWidth;
    height: $iconFooterWidth;
    margin-right: 10px;
  }
}
