@import './color.scss';

.input-invalid,
.input-invalid:focus,
.input-invalid:focus {
  fieldset {
    border-color: $error;
  }

  label {
    color: $error;
  }
}

.filter {
  select {
    padding: 10px 26px 10px 12px;
  }
}
