@import '../../css/color.scss';

.logo {
  width: $logoAppWidth;
  margin: 1rem;
  display: block;
}

.nav-subsection {
  padding-left: 2rem !important;
}

.myNavBar {
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: $backgroundNavBar;
  text-align: center;
  align-content: center;
  h3{
    color: $foregroundLightest;
    span{
      font-size: 0.8rem;
      letter-spacing: 2px;
      font-weight: normal;
    }
  }
}
#main-menu{
  li {
    padding: 0!important;
    .nav-subitem {
      width: 100%;
      padding-left: 32px;
      padding-right: 16px;
      min-height: 48px;
      display: flex;
      align-items: center;

      text-decoration: none!important;
      color: $foregroundDark;

      &:hover {
        color: $foregroundPrimary;
      }
    }

    &.main-menu-item {
      .nav-item {
        width: 100%;
        padding-inline: 16px;
        display: flex;
        align-items: center;
        min-height: 48px;

        text-decoration: none!important;
        color: $foregroundDark;

        &:hover {
          color: $foregroundPrimary;
        }
      }

      svg {
        color: $foregroundDark;
        padding-right: 5px;

        &:hover {
          color: $foregroundPrimary;
        }
      }
    }

    &:hover {
      color: $foregroundPrimary;

      svg {
        color: $foregroundPrimary;
      }

      .nav-item,
      .nav-subitem {
        color: $foregroundPrimary;
      }
    }
  }
}
.title-nav-subsection {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  color: $foregroundDarkSofter;

  svg {
    padding-right: 5px;
  }
}
#main-menu-dark{
  li {
    padding: 0!important;

    .nav-subitem {
      width: 100%;
      padding-left: 32px;
      padding-right: 16px;
      min-height: 48px;
      display: flex;
      align-items: center;

      text-decoration: none!important;
      color: $foregroundLightest;

      &:hover {
        color: $info;
      }
    }

    &.main-menu-item {
      .nav-item {
        width: 100%;
        padding-inline: 16px;
        display: flex;
        align-items: center;
        min-height: 48px;

        text-decoration: none!important;
        color: $foregroundLightest;

        &:hover {
          color: $info;
        }
      }

      svg {
        color: $foregroundLightest;
        padding-right: 5px;

        &:hover {
          color: $info;
        }
      }
    }

    &:hover {
      color: $info;

      svg {
        color: $info;
      }

      .nav-item,
      .nav-subitem {
        color: $info;
      }
    }
  }
  .title-nav-subsection {
    color: $foregroundLightest;
  }
}
.selectMulticenter {
  margin-top: 9px!important;
  select{padding: 5px 32px 5px 12px!important;}
}
@media(max-width: 414px) {
  .selectMulticenter {
    margin-top: 10px!important;
    select{font-size: 12px;
      padding: 5px 28px 5px 8px!important;
      line-height:10px;
    }
  }
}

@media (min-width: 600px) {
  :is(#main-menu, #main-menu-dark) {
    li {
      .nav-subitem {
        min-height: auto;
        padding-block: 6px;
      }


      &.main-menu-item {
        .nav-item {
          min-height: auto;
          padding-block: 6px;
        }
      }
    }
  }
}